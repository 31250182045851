<template>
  <b-card>


    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-overlay
            :show="uploading"
            rounded="circle">
          <b-avatar
              size="80"
              variant="light-primary"
              :src="user_avatar"
              class="badge-minimal"/>
        </b-overlay>
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">

        <div>
          <!-- upload button -->
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              :disabled="uploading"
              class="mb-75 mr-75"
              @click="$refs.refInputEl.$el.click()">

            上传

          </b-button>
        </div>

        <b-form-file
            ref="refInputEl"
            v-model="avatar_upload"
            accept=".jpg, .png,.jpeg"
            :hidden="true"
            plain
            @input="solveImg"/>
        <!--/ upload button -->

        <b-card-text>仅允许jpg与png格式的图片，最大不超过5M</b-card-text>
      </b-media-body>
      <div class="text-right only-lg">
        <b-badge
            variant="info"
            :hidden="user_type!=='01'"
            style="margin: 10px"
            class="badge-glow">
          管理员
        </b-badge>

        <b-badge
            variant="primary"
            :hidden="user_type!=='02'"
            style="margin: 10px"
            class="badge-glow">
          注册用户
        </b-badge>

        <b-badge
            variant="primary"
            :hidden="user_type!=='03'"
            style="margin: 10px"
            class="badge-glow">
          订阅用户
        </b-badge>

        <b-badge
            variant="success"
            :hidden="user_status!=='01'"
            style="margin: 10px"
            class="badge-glow">
          状态正常
        </b-badge>

        <b-badge
            variant="warning"
            :hidden="user_status!=='02'"
            style="margin: 10px"
            class="badge-glow">
          状态异常
        </b-badge>

        <b-badge
            variant="danger"
            :hidden="user_status!=='03'"
            style="margin: 10px"
            class="badge-glow">
          封禁
        </b-badge>
      </div>

    </b-media>


    <!-- / media -->

    <!-- form -->
    <b-form class="mt-2"
            @submit.prevent="submit"
    >
      <b-row>
        <b-col sm="6">
          <b-form-group
              label="用户名">
            <b-form-input
                v-model="user_name"
                placeholder=""
                disabled
                name="user_name"/>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
              label="邮箱">
            <b-form-input
                v-model="user_email"
                name="user_email"
                disabled
                placeholder=""/>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
              label="昵称">
            <b-form-input
                v-model="user_nickname"
                name="user_nickname"
                :state="nickname_individual"
                placeholder="请输入昵称（非必填）"/>
            <b-form-invalid-feedback
                :state="nickname_individual">
              请置空或输入2~16字符长度的昵称
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
              label="电话">
            <b-form-input
                v-model="user_telephone"
                name="user_telephone"
                :state="telephone_individual"
                placeholder="请输入电话（非必填）"/>
            <b-form-invalid-feedback
                :state="telephone_individual">
              请置空或输入正确的电话号码
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
              label="个人网站">
            <b-form-input
                v-model="user_site"
                :state="site_individual"
                name="user_site"
                placeholder="请输入个人网站地址（非必填）"/>
            <b-form-invalid-feedback
                :state="site_individual">
              请置空或输入正确的域名
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>


        <b-col sm="6">
          <b-form-group
              label="生日">
            <b-form-datepicker
                id="user_birthday"
                v-model="user_birthday"
                :hide-header="true"
                :locale="locale"
                v-bind="labels[locale] || {}"
                class="mb-1"/>
          </b-form-group>
        </b-col>

        <b-col sm="12">
          <b-form-group
              label="签名">
            <b-form-textarea
                id="textarea-default"
                :state="slogan_individual"
                v-model="user_slogan"
                placeholder="请输入签名（非必填）"
                rows="3"/>
            <b-form-invalid-feedback
                :state="slogan_individual">
              请置空或输入1~140字符的签名
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col sm="12">
          <b-form-group
              label="性别">
            <b-form-radio-group
                v-model="user_gender"
                :options="gender_options"
                class="demo-inline-spacing"
                name="radio-validation">
            </b-form-radio-group>
          </b-form-group>
        </b-col>


        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="mt-2 mr-1">
            保存
          </b-button>


        </b-col>
      </b-row>
    </b-form>
    <b-modal
        ok-only
        v-model="avatar_modal_show"
        @ok="upload_avatar"
        ok-title="提交"
        centered
        title="裁剪图片">
      <div class="cropper-content">
        <div class="cropper" style="text-align:center">
          <vue-cropper
              ref="cropper"
              :img="option.img"
              :outputSize="option.size"
              :outputType="option.outputType"
              :info="true"
              :full="option.full"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :autoCrop="option.autoCrop"
              :fixed="option.fixed"
              :fixedNumber="option.fixedNumber"
              :centerBox="option.centerBox"
              :infoTrue="option.infoTrue"
              :fixedBox="option.fixedBox"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
          ></vue-cropper>
        </div>
      </div>
    </b-modal>
  </b-card>


</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BAvatar,
  BBadge,
  BFormRadioGroup,
  BFormDatepicker,
  BFormTextarea,
  BModal,
  BOverlay,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {VueCropper} from 'vue-cropper'
import Toast from "@core/components/toastification/ToastificationContent";
import User from "@/store/userInfo";

export default {

  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    BBadge,
    BFormRadioGroup,
    BFormDatepicker,
    BFormTextarea,
    VueCropper,
    BModal,
    BOverlay,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  async created() {
    await User.checkUserInfo()
    let user = User.userInfo
    this.user_avatar = user.user_profile.user_avatar
    this.user_name = user.user.user_name
    this.user_email = user.user.user_email
    this.user_type = user.user.user_type
    this.user_status = user.user.user_status

    if (user.user_profile.user_nickname != null)
      this.user_nickname = user.user_profile.user_nickname
    if (user.user_profile.user_telephone != null)
      this.user_telephone = user.user_profile.user_telephone
    if (user.user_profile.user_site != null)
      this.user_site = user.user_profile.user_site
    if (user.user_profile.user_birthday != null)
      this.user_birthday = user.user_profile.user_birthday
    if (user.user_profile.user_slogan != null)
      this.user_slogan = user.user_profile.user_slogan
    if (user.user_profile.user_gender != null)
      this.user_gender = user.user_profile.user_gender
  },
  data() {
    return {
      uploading: false,
      avatar_upload: null,
      avatar_modal_show: false,

      user_avatar: null,
      user_name: null,
      user_email: null,
      user_type: null,
      user_status: null,

      user_telephone: "",
      user_nickname: "",
      user_gender: "",
      user_birthday: "",
      user_site: "",
      user_slogan: "",

      telephone_individual: null,
      nickname_individual: null,
      gender_individual: null,
      birthday_individual: null,
      site_individual: null,
      slogan_individual: null,

      user_telephone_individual: null,
      user_nickname_individual: null,
      user_gender_individual: null,
      user_birthday_individual: null,
      user_site_individual: null,
      user_slogan_individual: null,


      gender_options: [
        {text: '男', value: '01'},
        {text: '女', value: '02'},
        {text: '保密', value: '03'},
      ],

      locale: 'zh',
      labels: {
        zh: {
          labelPrevDecade: '过去十年',
          labelPrevYear: '上一年',
          labelPrevMonth: '上个月',
          labelCurrentMonth: '当前月份',
          labelNextMonth: '下个月',
          labelNextYear: '明年',
          labelNextDecade: '下一个十年',
          labelToday: '今天',
          labelSelected: '选定日期',
          labelNoDateSelected: '未选择日期（非必选）',
          labelCalendar: '日历',
          labelNav: '日历导航',
          labelHelp: '使用光标键浏览日期',
        },
      },
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "webp", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        canMove: true,
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 500, // 默认生成截图框宽度
        autoCropHeight: 500, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
    }
  },
  methods: {
    submit() {
      let pass = true

      //验证昵称
      if ((this.user_nickname.length > 16 || this.user_nickname.length < 2) && this.user_nickname !== "" && this.user_nickname !== null) {
        pass = false
        this.nickname_individual = false
      } else {
        this.nickname_individual = null
      }

      //验证手机号
      let pattern = /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d)|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d))$)/
      if (!pattern.test(this.user_telephone) && this.user_telephone !== "" && this.user_telephone !== null) {
        pass = false
        this.telephone_individual = false
      } else {
        this.telephone_individual = null
      }


      //验证域名
      pattern = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
      if (!pattern.test(this.user_site) && this.user_site !== "" && this.user_site !== null) {
        pass = false
        this.site_individual = false
      } else {
        this.site_individual = null
      }

      //验证签名
      if ((this.user_slogan.length > 140 || this.user_slogan.length < 1) && this.user_slogan !== "" && this.user_slogan !== null) {
        pass = false
        this.slogan_individual = false
      } else {
        this.slogan_individual = null
      }

      if (!pass) {
        return
      }

      this.$http.post("/api/system/user/update/userInfo", {
        "user_nickname": this.user_nickname,
        "user_telephone": this.user_telephone,
        "user_site": this.user_site,
        "user_birthday": this.user_birthday,
        "user_slogan": this.user_slogan,
        "user_gender": this.user_gender
      }).then(response => {
        if (response.data.code === 200) {
          this.showToast("success", "成功", "AlertCircleIcon", "信息修改完成", "top-center", 2000)
        } else {
          this.showToast("danger", "抱歉", "AlertCircleIcon", response.data.data, "top-center", 3000)
        }
      }).catch(error => {
        this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误，" + error, "top-center", 3000)
      })

      console.log(this.user_birthday)
    },
    solveImg() {
      if (!(this.avatar_upload.type === 'image/jpeg' || this.avatar_upload.type === 'image/png' || this.avatar_upload.type === 'image/jpg')) {
        this.showToast("danger", "抱歉", "AlertCircleIcon", "您必须上传jpg或png格式的图片", "top-center", 2000)
        return
      }
      if (!(this.avatar_upload.size / 1024 / 1024 < 5)) {
        this.showToast("danger", "抱歉", "AlertCircleIcon", "您必须上传的图片必须小于5M", "top-center", 2000)
        return
      }

      let reader = new FileReader()
      reader.readAsDataURL(this.avatar_upload)
      reader.onload = e => {
        this.option.img = e.target.result
      }
      this.avatar_modal_show = true;
    },
    upload_avatar() {
      this.uploading = true
      this.$refs.cropper.getCropBlob((data) => {

        let formData = new FormData()
        formData.append('file', data)
        this.$http.post("/api/system/file/upload/image", formData).then(response => {
          if (response.data.code === 200) {
            this.$http.post("/api/system/user/update/avatar", {'img': response.data.data.file_url}).then(responseUpload => {
              if (responseUpload.data.code === 200) {
                this.user_avatar = response.data.data.file_url
                User.userInfo.user_profile.user_avatar = this.user_avatar
                this.showToast("success", "成功", "AlertCircleIcon", "头像修改完成", "top-center", 2000)
                location.reload()
              } else {
                this.showToast("danger", "抱歉", "AlertCircleIcon", responseUpload.data.data, "top-center", 3000)
              }
              this.uploading = false
            }).catch(error => {
              this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误，" + error, "top-center", 3000)
              this.uploading = false
            })
          } else {
            this.showToast("danger", "抱歉", "AlertCircleIcon", response.data.data, "top-center", 3000)
            this.uploading = false
          }
        }).catch(error => {
          this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误，" + error, "top-center", 3000)
          this.uploading = false
        })
      })
    },
    showToast(variant, title, icon, text, position, timeout) {
      this.$toast({
            component: Toast,
            props: {
              title: title,
              icon: icon,
              text: text,
              variant,
            },
          },
          {
            position,
            timeout
          })
    },
  },
}
</script>

<style>
@media screen and (max-width: 550px ) {
  .only-lg {
    display: none;
  }
}

.cropper-content .cropper {
  width: auto;
  height: 300px;
}
</style>