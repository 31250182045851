<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <b-col md="6">
          <b-form-group
              label="旧密码"
              label-for="account-old-password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                  id="account-old-password"
                  v-model="passwordValueOld"
                  name="old-password"
                  :type="passwordFieldTypeOld"
                  :state="old_password_individual"
                  @input="displayIndividual"
                  placeholder="旧密码"/>
              <b-input-group-append is-text>
                <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"/>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback
                :state="old_password_individual">
              密码错误
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
              label-for="account-new-password"
              label="新密码">
            <b-input-group class="input-group-merge">
              <b-form-input
                  id="account-new-password"
                  v-model="newPasswordValue"
                  :type="passwordFieldTypeNew"
                  :state="new_password_individual"
                  @input="displayIndividual"
                  name="new-password"
                  placeholder="新密码"/>
              <b-input-group-append is-text>
                <feather-icon
                    :icon="passwordToggleIconNew"
                    class="cursor-pointer"
                    @click="togglePasswordNew"/>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback
                :state="new_password_individual">
              密码为必须包含大小写字母与数字的8~32位的组合强密码，且仅能包含字母数字与符号
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
              label-for="account-retype-new-password"
              label="请重新输入新密码"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                  id="account-retype-new-password"
                  v-model="RetypePassword"
                  :state="new_password2_individual"
                  :type="passwordFieldTypeRetype"
                  name="retype-password"
                  @input="displayIndividual"
                  placeholder="新密码"
              />
              <b-input-group-append is-text>
                <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback
                :state="new_password2_individual">
              两次密码输入不一致或密码强度不满足
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="submit"
              class="mt-1 mr-1">
            保存
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Toast from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  created() {
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      old_password_individual: null,
      new_password_individual: null,
      new_password2_individual: null,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    displayIndividual() {
      this.new_password_individual = null;
      this.new_password2_individual = null;
      this.old_password_individual = null;
    },
    submit() {
      let pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/
      this.new_password_individual = pattern.test(this.newPasswordValue);
      this.new_password2_individual = (this.newPasswordValue === this.RetypePassword) && this.new_password_individual
      this.old_password_individual = !(this.passwordValueOld === '')
      if (this.old_password_individual)
        this.old_password_individual = null
      if (!this.new_password_individual || !this.new_password2_individual) {
        return
      }
      this.$http.post('/api/system/user/update/password', {
        'password_old': this.$md5(this.passwordValueOld),
        'password': this.$md5(this.newPasswordValue)
      }).then(response => {
        if (response.data.code === 200) {
          this.showToast("success", "成功", "AlertCircleIcon", "您的密码已经修改", "top-center", 3000)
          this.displayIndividual()
        } else if (response.data.code === 400005) {
          this.old_password_individual = false
        } else {
          this.showToast("danger", "抱歉", "AlertCircleIcon", response.data.code, "top-center", 5000)
          this.displayIndividual()
        }

      }).catch(error => {
        this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误，" + error, "top-center", 5000)
        this.displayIndividual()
      });
    },
    showToast(variant, title, icon, text, position, timeout) {
      this.$toast({
            component: Toast,
            props: {
              title: title,
              icon: icon,
              text: text,
              variant,
            },
          },
          {
            position,
            timeout
          })
    },
  },
}
</script>
