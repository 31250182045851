<template>
  <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left">

    <b-tab active>

      <template #title>
        <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"/>
        <span class="font-weight-bold">账户设置</span>
      </template>

      <account-setting-general/>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"/>
        <span class="font-weight-bold">修改密码</span>
      </template>

      <account-setting-password/>
    </b-tab>

    <b-tab>

      <template #title>
        <feather-icon
            icon="ShieldIcon"
            size="18"
            class="mr-50"/>
        <span class="font-weight-bold">账户安全</span>
      </template>

      <account-setting-security/>
    </b-tab>

    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
            icon="BellIcon"
            size="18"
            class="mr-50"/>
        <span class="font-weight-bold">通知设置</span>
      </template>

      <account-setting-notification/>
    </b-tab>
  </b-tabs>
</template>

<script>
import {BTabs, BTab} from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingSecurity from './AccountSettingSecurity.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingSecurity,
    AccountSettingNotification,
  },
}
</script>
