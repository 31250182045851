<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        站内信息
      </h6>
      <b-col
          cols="12"
          class="mb-2">
        <b-form-checkbox
            name="check-button"
            switch
            inline>
          <span>自选股异动</span>
        </b-form-checkbox>
      </b-col>
      <b-col
          cols="12"
          class="mb-2">
        <b-form-checkbox
            name="check-button"
            switch
            inline>
          <span>自选股收盘提醒</span>
        </b-form-checkbox>
      </b-col>
      <b-col
          cols="12"
          class="mb-2">
        <b-form-checkbox
            name="check-button"
            switch
            inline>
          <span>大盘信息</span>
        </b-form-checkbox>
      </b-col>
      <b-col
          cols="12"
          class="mb-2">
        <b-form-checkbox
            name="check-button"
            switch
            inline>
          <span>打新提醒</span>
        </b-form-checkbox>
      </b-col>

      <!-- application switch -->
      <h6 class="section-label mx-1 mt-2">
        邮件信息
      </h6>
      <b-col
          cols="12"
          class="mt-1 mb-2">
        <b-form-checkbox
            name="check-button"
            switch
            inline>
          <span>登录提醒</span>
        </b-form-checkbox>
      </b-col>
      <b-col
          cols="12"
          class="mb-2">
        <b-form-checkbox
            name="check-button"
            switch
            inline>
          <span>站内信息同步</span>
        </b-form-checkbox>
      </b-col>
      <b-col
          cols="12"
          class="mb-2">
        <b-form-checkbox
            name="check-button"
            switch
            inline>
          <span>订阅推送</span>
        </b-form-checkbox>
      </b-col>
      <!--/ application switch -->

      <!-- buttons -->
      <b-col cols="12">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1 mt-1">
          保存
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {}
  },
}
</script>
